<template>
  <div class="ad">
    <div class="info">
      <div>
        <h3>Новогодняя программа под ключ</h3>
        <div class="card">
          <ul>
            <li class="title">Что включено:</li>
            <li v-for="(item, i) in list" :key="i">{{ i + 1 }}. {{ item }}</li>
          </ul>
        </div>
      </div>
      <a href="#" @click.prevent="show = !show">160000<small>₽</small></a>
    </div>
    <Image :path="'dance_gitl'" />
  </div>
  <teleport to="#overlay">
    <Overlay v-if="show" @click.self="show = false" >
      <UserForm :btnLabel="'Отправить заявку'" :persons="15">
        <ModalClose @click="show = false"/>
      </UserForm>
    </Overlay>
  </teleport>
</template>
<script>
import Image from "./Image.vue";
import UserForm from "./UserForm.vue";
import Overlay from "./Overlay.vue";
import ModalClose from "./modals/ModalClose.vue";

export default {
  name: "AdBlockBundle",
  components: { Image, UserForm, Overlay, ModalClose },
  data() {
    return {
      show: false,
      showForm: false,
      list: [
        "Ведущий",
        "Диджей",
        "Иллюзионист",
        "Фотосъемка",
        "Салют",
      ],
    };
  },
};
</script>
<style scoped>
.ad {
  border-radius: var(--radius);
  overflow: hidden;
  /* display: flex; */
  justify-content: center;
  align-items: flex-start;
  position: relative;
}
.ad img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.info {
  color: #ffffff;
  z-index: 2;
  padding: 2em;
  /* position: absolute; */
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  /* font-size: 2.2em; */
}
h3 {
  font-size: 2.5em;
  line-height: 1.1;
  font-weight: bolder;
  text-transform: none;
  color: white;
}
.name {
  text-transform: uppercase;
  font-size: 2.5em;
}
a {
  padding: 0.2em 1.5em;
  background: linear-gradient(180deg, #ff76da 26.47%, #eba5d8 100%);
  border-radius: 6px;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  font-size: 2em;
}
a small {
  font-size: 0.5em;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 400ms ease-in-out;
  transition-delay: 200ms;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(calc(100% * 2));
}
ul {
  list-style: none;
  font-size: 1.2em;
  padding: 0;
}
.card {
  margin: 1em 0;
  padding: 1em;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 10px;
  transform: all 100ms;
  min-height: 100px;
  backdrop-filter: blur(4px);
}
li.title {
  font-size: 1.4em;
}
</style>
