<template>
  <div class="slider">
    <div class="info">
      <h1>{{ title }}</h1>
      <Button :link="'tel:' + tel">{{ phone }}</Button>
    </div>
    <div
      class="info mobile"
      :style="{
        backgroundImage: `url(${require('@/assets/photos/header_slide-w800.jpg')})`,
      }"
    >
      <h1>{{ title }}</h1>
      <Button :link="'tel:' + tel">{{ phone }}</Button>
    </div>
    <Image :path="'header_slide'" />
  </div>
</template>
<script>
import Image from "./Image.vue";
import Button from "./Button.vue";

export default {
  name: "Slider",
  props: ["image"],
  components: { Image, Button },
  data() {
    return {
      title: "Корпоративные праздники в эко-парке “Вереск”",
      phone: "+7 (921) 569-35-36",
      tel: "+79215693536",
    };
  },
};
</script>
<style lang="css" scoped>
.slider {
  width: 100%;
  max-height: 55vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
  position: relative;
}
.info {
  position: absolute;
  right: 0;
  top: 0;
  width: 80%;
  padding: 2em;
  text-align: right;
}
.info.mobile {
  display: none;
}
h1 {
  color: #ffffff;
  text-align: right;
  font-weight: bold;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 18px;
}

.slider img {
  width: 100%;
}
@media (max-width: 800px) {
  .info.mobile {
    display: block;
    position: unset;
    width: unset;
    text-align: center;
  }
  .slider {
    display: unset;
    max-height: unset;
  }
  .info {
    display: none;
  }
  h1 {
    font-size: 1.5rem;
    text-align: center;
  }
  .slider img {
    display: none;
  }
}
</style>
