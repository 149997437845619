<template>
  <div class="block">
    <div class="slide" @click="showNextSlide">
      <img
        ref="slide"
        :src="require(`../assets/gallery/ny_${current}.jpg`)"
        alt=""
        :srcset="
          require(`../assets/gallery/ny_${current}.jpg`) +
          ' 320w, ' +
          require(`../assets/gallery/ny_${current}@2x.jpg`) +
          ' 480w, ' +
          require(`../assets/gallery/ny_${current}@3x.jpg`) +
          ' 800w '
        "
      />
    </div>
    <div class="thumbs" ref="container">
      <div
        class="thumb"
        :class="{ current: current === i }"
        v-for="i in total"
        :key="i"
        @click="current = i"
        :ref="`slide_${i}`"
      >
        <img
          :src="require(`../assets/gallery/ny_${i}.jpg`)"
          alt=""
          :srcset="
            require(`../assets/gallery/ny_${i}.jpg`) +
            ' 320w, ' +
            require(`../assets/gallery/ny_${i}@2x.jpg`) +
            ' 480w, ' +
            require(`../assets/gallery/ny_${i}@3x.jpg`) +
            ' 800w '
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  methods: {
    showNextSlide() {
      this.current =
        this.current < this.slides.length - 1 ? this.current + 1 : 0;
      let xPosition = this.$refs["slide_" + this.current].offsetLeft;
      this.$refs.container.scrollTo(xPosition, 0);
    },
  },
  data() {
    return {
      total: [
        2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24, 25, 26, 27, 28, 29, 30, 31
      ],
      current: 2,
    };
  },
};
</script>

<style scoped>
.block {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
}

.slide {
  display: flex;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: var(--radius);
  overflow: hidden;
  max-height: 70vh;
}

.thumbs {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  border-radius: var(--radius) var(--radius) 0 0;
  padding-bottom: 1em;
  scroll-behavior: smooth;
}

.thumb {
  overflow: hidden;
  height: 152px;
  width: 213px;
  border-radius: var(--radius);
  margin-right: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
  border: 5px solid transparent;
  transition: all 200ms;
}

.thumb:last-child {
  margin-right: 0;
}

.thumb.current {
  border: 5px solid var(--bgVeresk);
}

.thumbs::-webkit-scrollbar {
  height: 5px;
  margin-top: 10px;
}

.thumbs::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 5px;
  background-color: #fff;
}

.thumbs::-webkit-scrollbar-thumb {
  background-color: rgba(216, 161, 204, 0.74);
  border-radius: 4px;
  transition: all 200ms;
}

.thumbs::-webkit-scrollbar-thumb:hover {
  background-color: #d8a1cc;
}

.thumb img {
  min-height: inherit;
  max-height: 110%;
  /*object-fit: cover;*/
  display: flex;
}

.slide img {
  width: 100%;
}

@media (max-width: 420px) {
  .thumb {
    height: 80px;
    width: 110px;
    margin-right: 15px;
  }

  .block {
    grid-gap: 15px;
  }
}
</style>
