<template>
  <div class="block">
    <div>
      <h3>Новогодний конструктор</h3>

      <div v-for="(option, i) in options" :key="i" class="line">
        <span class="info">
          <input
            type="checkbox"
            :id="'ckb_' + i"
            v-model="option.checked"
            :disabled="option.disable"
          />
          <label :for="'ckb_' + i">{{ option.title }} </label>
        </span>
        <span class="price" v-if="option.perPerson">
          <small>от </small>{{ option.price * persons }}<small>₽</small>
        </span>
        <span class="price" v-else-if="option.transfer">
          <small>от </small>{{ this.transfer }}<small>₽</small>
        </span>
        <span class="price" v-else>
          <small>от </small>{{ option.price }}<small>₽</small>
        </span>
      </div>
    </div>
    <div class="footer">
      <div class="guests">
        <p>Количество гостей</p>
        <img src="@/assets/images/dancing_people.svg" />
        <input type="number" v-model="persons" @change="limitCheck($event)" />
      </div>
      <div class="summa">
        <p><small>от </small>{{ checkout }}<small>₽</small></p>
        <a href="#" class="btn" @click.prevent="show = !show">
          Оставить заявку
        </a>
      </div>
    </div>
  </div>
  <teleport to="#overlay">
    <Overlay v-if="show" @click.self="show = false">
      <UserForm :btnLabel="'Отправить заявку'" :persons="persons">
        <ModalClose @click="show = false" />
      </UserForm>
    </Overlay>
  </teleport>
</template>
<script>
import UserForm from "./UserForm.vue";
import Overlay from "./Overlay.vue";
import ModalClose from "./modals/ModalClose.vue";
export default {
  name: "Calculator",
  components: { UserForm, Overlay, ModalClose },
  data() {
    return {
      show: false,
      persons: 15,
      personsLimit: 15,
      summa: 100,
      options: [
        {
          title: 'Аренда банкетного зала "Линтула"',
          price: 50000,
          checked: true,
          perPerson: false,
          disable: true,
        },
        {
          title: "Банкетное Меню",
          price: 3500,
          checked: true,
          perPerson: true,
          disable: true,
        },
        {
          title: "Ведущие на мероприятия",
          price: 80000,
          checked: false,
          perPerson: false,
        },
        {
          title: "Новогодний квест ",
          price: 2500,
          checked: false,
          perPerson: true,
        },
        {
          title: "Тимбилдинги, новогодние гуляния",
          price: 25000,
          checked: false,
          perPerson: false,
        },
        {
          title: "Караоке батл (оборудование, оператор, бэк вокалистка)",
          price: 40000,
          checked: false,
          perPerson: false,
        },
        {
          title: "Иллюзионист (микромагия на встрече + выступление)",
          price: 50000,
          checked: false,
          perPerson: false,
        },
        {
          title: "Музыкальная группа",
          price: 50000,
          checked: false,
          perPerson: false,
        },
        {
          title: "Взрослые аниматоры",
          price: 30000,
          checked: false,
          perPerson: false,
        },
        {
          title: "Организация фото-видео съемки",
          price: 20000,
          checked: false,
          perPerson: false,
        },
        {
          title: "Ролик переозвучка",
          price: 40000,
          checked: false,
          perPerson: false,
        },
        { title: "Салют", price: 30000, checked: false, perPerson: false },
        {
          title: "Организация трансфера СПб • Вереск • Спб",
          price: 5000, //за 10 человек
          checked: false,
          perPerson: false,
          transfer: true,
        },
      ],
    };
  },
  computed: {
    transfer() {
      // let transfer = this.options.find((o) => o?.transfer);
      // return Math.ceil(this.persons / 10) * transfer.price;
      return this.persons >= 15 && this.persons <= 20
        ? 15000
        : this.persons > 20 && this.persons <= 40
        ? 20000
        : this.persons > 40 && this.persons <= 60
        ? 30000
        : 60000;
    },
    checkout() {
      let output = this.options
        .filter((o) => o.checked && !o.perPerson && !o?.transfer)
        .reduce((a, b) => a + b.price, 0);

      let personsOutput = this.options
        .filter((o) => o.checked && o.perPerson)
        .reduce((a, b) => a + b.price * this.persons, 0);

      let transfer = this.options.find((o) => o?.transfer).checked
        ? this.transfer
        : 0;
      console.log(transfer);
      return output + personsOutput + transfer;
    },
  },
  methods: {
    limitCheck(e) {
      this.persons =
        this.persons < this.personsLimit
          ? this.personsLimit
          : parseFloat(e.target.value);
    },
  },
  // updated() {
  //   console.log(this.$root.transfer);
  // },
};
</script>
<style lang="css" scoped>
.block {
  color: white;
  background: linear-gradient(180deg, #ff76da 26.47%, #eba5d8 100%);
  border-radius: var(--radius);
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 150ms;
  max-height: auto;
}
h3 {
  color: white;
  margin-bottom: 0.6em;
}
.line {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 0.3em;
}
.info {
  display: flex;
  align-items: center;
  line-height: 1;
}
label {
  cursor: pointer;
}
input[type="number"] {
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid #ffffff;
  border-radius: 5px;
  padding: 0.3em 0.6em;
  appearance: none;
  width: 2em;
  text-align: center;
  font-size: 1.2em;
  color: white;
}
input:focus {
  outline: none;
}
input::-webkit-inner-spin-button {
  display: none;
}
input[type="checkbox"] {
  outline: none;
  background: unset;
  appearance: none;

  height: 1.45em;
  width: 1.45em;
  position: relative;
  margin-right: 1.2em;
  cursor: pointer;
  flex-shrink: 0;
}
input[type="checkbox"]::after,
input[type="checkbox"]::before {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid white;
  border-radius: 4px;
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  /* transform: translateX(-50%); */
  transform: translateY(-50%) translateX(-50%);
}
input[type="checkbox"]::before {
  content: "";
  width: 60%;
  height: 60%;
  border-radius: 2px;
  border: none;
}
input[type="checkbox"]:checked::before {
  background: white;
}
input[type="checkbox"]:disabled {
  opacity: 0.5;
}
.price {
  font-size: 1.2em;
}
small {
  font-size: 0.6em;
}
.footer {
  margin-top: 1em;
  border-top: 1px solid white;
  padding: 1em 0 0;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr;
}
.footer .summa {
  font-size: 2.6em;
  margin-top: -0.3em;
  display: inline-flex;
  flex-direction: column;
  text-align: right;
}
.footer .guests {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0.5em;
  align-items: end;
}
.footer .guests p {
  grid-column: span 2;
  margin: 0;
}
.btn {
  line-height: 1;
  background: white;
  border-radius: 5px;
  color: #eba5d8;
  padding: 0.8em 1em 0.6em;
  text-transform: uppercase;
  font-size: 18px;
  text-decoration: none;
  text-align: center;
  translate: all 200ms;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0);
}
.btn:hover {
  box-shadow: 4px 2px 8px rgba(0, 0, 0, 0.3);
}
@media (max-width: 800px) {
  .footer {
    grid-template-columns: 1fr;
  }
  .line {
    font-size: 1rem;
    flex-direction: column;
    border-bottom: 1px solid #ffffff62;
    margin-bottom: 1rem;
  }
  .line:last-child {
    border-bottom: unset;
  }
  .price {
    text-align: right;
    font-size: 1.6em;
  }
}
</style>
