<template>
  <a :href="link"><slot /></a>
</template>
<style scoped>
a {
  background: white;
  padding: 10px 30px 8px;
  color: #ff76da;
  text-decoration: none;
  font-size: 1.7em;
  font-weight: bold;
  border-radius: 10px;
  display: inline-block;
}
a:hover {
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.25);
}
@media (max-width: 800px) {
  a {
    font-size: 1.3em;
    width: 100%;
    box-sizing: border-box;
  }
}
</style>
<script>
export default {
  name: "Button",
  props: ["link", "type", "color", "size"],
};
</script>
