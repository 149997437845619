<template>
  <Header />
  <div class="layout">
    <Slider />
    <div class="grid">
      <ContentField>
        <p>
          Эко-парк «Вереск» находятся всего в часе езды от Санкт-Петербурга и в
          нескольких километрах от Зеленогорска на берегу озера Большое
          Симагинское (оз. Красавица).
        </p>
        <p>
          Всесезонный панорамный банкетный зал «Линтула» с видом на живописное
          лесное озеро, каскадной террасой, спускающаяся к озеру, большой
          парковкой, все необходимое оборудование и уникальная дружеская
          атмосфера.
        </p>
        <p>
          Зал «Линтула» при комфортной рассадке вмещает 70 - 80 человек,
          максимальная вместимость до 100 гостей. Банкетный зал оборудован по
          последнему слову техники: профессиональный звук и световое
          оборудование для шумных вечеринок, большой экран с проектором.
        </p>
        <p>
          По территории эко-парка и на берегу озера можно организовать
          тимбилдинг, квесты, развлекательные игры.
        </p>
      </ContentField>
      <ContentField>
        <p><strong>В стоимость аренды включено:</strong></p>
        <ul>
          <li>Профессиональное звуковое и световое оборудование, проектор</li>
          <li>
            Декорирование зала в новогодней тематике с настоящей новогодней
            елкой
          </li>
         
          <li>Базовое оформление текстилем и сервировкой</li>
          <li>Время аренды <strong> с 14:00 до 00:00</strong></li>
          <li>
            <strong
              >Возможность продления площадки после 00:00 (стоимость аренды
              составляет 10 000 рублей в час по предварительной заявке до
              19:00)</strong
            >
          </li>
        </ul>
      </ContentField>
      <AdBlock />
      <SimpleSlider />
      <ContentField>
        <MenuVariants />
      </ContentField>
    </div>
    <div class="grid reverse">
      <img
        class="transfer"
        src="@/assets/images/transfer_art.svg"
        alt="Как добраться до экопарка Вереск"
      />
      <div class="transfer">
        <div class="info">
          <h3>Организация трансфера</h3>
          <p>Санкт-Петербург • Вереск • Санкт-Петербург</p>
        </div>
        <button @click.prevent="emitTransfer">
          <span><small>от </small>10000<small>₽</small></span>
          <img src="@/assets/images/bus_icon.svg" alt="Автобус" />
          <span>20 мест</span>
        </button>
      </div>
      <Calculator />
      <AdBlockBundle />
    </div>
    <Gallery />
    <YaMap />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import ContentField from "./components/ContentField.vue";
import AdBlock from "./components/AdBlock.vue";
import AdBlockBundle from "./components/AdBlockBundle.vue";
import MenuVariants from "./components/MenuVariants.vue";
import YaMap from "./components/YaMap.vue";
import Slider from "./components/Slider.vue";
import SimpleSlider from "./components/SimpleSlider.vue";
import Calculator from "./components/Calculator.vue";
import Gallery from "./components/Gallery.vue";

export default {
  name: "App",
  components: {
    Header,
    ContentField,
    SimpleSlider,
    YaMap,
    Slider,
    AdBlock,
    AdBlockBundle,
    MenuVariants,
    Calculator,
    Gallery,
  },
  data() {
    return {
      menu: {},
      transfer: false,

    };
  },
  methods: {
    emitTransfer() {
      this.$emit("addTransfer", { transfer: this.transfer });
    },
  },
  mounted() {
    document.title = `Корпоратив в эко-парке "Вереск"`;
    let params = new URLSearchParams(document.location.search.substring(1));
    console.log(document.location.search.substring(1));

    console.log("test", params.get("test"));
    console.log("sec", parseInt(params.get("sec")));
  },
};
</script>

<style>
@import url("./assets/css/vars.css");

.layout {
  /* background: rgb(255, 142, 142); */
  box-sizing: border-box;
  /* min-height: 100vh; */
  display: grid;
  grid-gap: var(--gap);
  max-width: 1400px;
  min-width: 1020px;
  width: 100%;
  margin: 0 auto;
  padding: var(--layoutPadding);
}
body {
  font-size: 16px;
  color: var(--textColor);
  font-weight: normal;
  font-feature-settings: "pnum" on;
  font-feature-settings: "onum" on;
}
strong {
  color: #555555;
}
h1,
h2,
h3 {
  text-transform: uppercase;
  font-weight: 400;
  color: #555555;
}
h1 {
  font-size: 3em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.5em;
}
h4 {
  font-size: 1em;
  font-weight: 600;
}
ul {
  list-style-position: inside;
  padding-left: 0.5em;
}
.grid {
  display: grid;
  grid-template-columns: 4fr 5fr;
  grid-gap: var(--gap);
  grid-template-areas: "block ad" "block ad";
}
.grid.reverse {
  grid-template-columns: 5fr 4fr;
}
img.transfer {
  width: 100%;
}
.transfer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;
}
.transfer .info p {
  font-size: 1.3em;
}
.transfer button {
  width: 100%;
  appearance: none;
  border: none;
  background: linear-gradient(
    rgb(255, 118, 218) 26.47%,
    rgb(235, 165, 216) 100%
  );
  color: white;
  padding: 0.5em 1.6em;
  font-size: 2em;
  border-radius: 10px;
  font-family: "Circe", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button small {
  font-size: 0.5em;
}
.transfer button img {
  margin: 0 0.5em;
}
@media (max-width: 1024px) {
  .layout {
    min-width: 796px;
  }
}
@media (max-width: 800px) {
  .grid {
    display: flex;
    flex-direction: column;
  }
  .layout {
    min-width: unset;
    padding: 1rem;
  }
  body {
    font-size: 15px;
  }
  .transfer button {
    font-size: 1.45em;
    padding: 1em 1.4em;
  }
  .transfer .info {
    width: 100%;
    text-align: center;
  }
  .transfer .info p {
    font-size: 1em;
    margin-bottom: 1rem;
  }
}
</style>
