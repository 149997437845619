<template>
  <div class="slide">
    <Image :path="'table'" />
  </div>
</template>
<script>
import Image from "./Image.vue";
export default {
  name: "SimpleSlider",
  components: { Image },
};
//TODO: Дописать функционал слайдера
</script>

<style scoped>
.slide {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  max-height: 50vh;
  border-radius: var(--radius);
}
.slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
