<template>
  <img ref="myImg" src="" srcset="" />
</template>
<script>
export default {
  name: "Image",
  props: ["path"],
  mounted() {
    let img = require(`@/assets/photos/${this.path}-w1200.jpg`);
    let srcset =
      require(`@/assets/photos/${this.path}-w1200.jpg`) +
      " 1200w," +
      require(`@/assets/photos/${this.path}-w800.jpg`) +
      " 800w," +
      require(`@/assets/photos/${this.path}-w450.jpg`) +
      " 450w";
    this.$refs.myImg.src = img;
    this.$refs.myImg.srcset = srcset;
  },
};
</script>
<style >

</style>