<template>
  <div class="ad">
    <div class="info">
      <h3>Аренда банкетного зала</h3>
      <p class="name">"Линтула"</p>
      <p class="price">50 000<small>₽</small></p>
      <a href="#" @click.prevent="show = true">Весь декабрь</a>
    </div>
    <Image :path="'winter_lintula'" />
  </div>
  <teleport to="#overlay">
    <Overlay v-if="show" @click.self="show = false">
      <UserForm :btnLabel="'Отправить заявку'" :persons="15">
        <ModalClose @click="show = false" />
      </UserForm>
    </Overlay>
  </teleport>
</template>
<script>
import Image from "./Image.vue";
import UserForm from "./UserForm.vue";
import Overlay from "./Overlay.vue";
import ModalClose from "./modals/ModalClose.vue";
export default {
  name: "AdBlock",
  components: { Image, UserForm, Overlay, ModalClose },
  data() {
    return {
      show: false,
    };
  },
};
</script>
<style scoped>
.ad {
  /* grid-area: ad; */
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  /* grid-column-start:2 ;
  grid-column-end: 3 ;
  grid-row-start: 1;
  grid-row-end: span 2; */
  /* max-height: 100%; */
  border-radius: var(--radius);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}
.ad img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.ad:after {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 62%);
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 2;
}
.info {
  color: #ffffff;
  z-index: 3;
  text-align: center;
  align-self: start;
  padding: 3em;
  position: absolute;
  /* font-size: 2.2em; */
}
h3 {
  font-size: 2.2em;
  text-transform: none;
  color: white;
}
.name {
  text-transform: uppercase;
  font-size: 2.5em;
}
.price {
  font-size: 6em;
  line-height: 1;
}
.price small {
  font-size: 0.4em;
}
a {
  align-items: center;
  padding: 10px 30px;
  background: linear-gradient(180deg, #ff76da 26.47%, #eba5d8 100%);
  border-radius: 6px;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 30px;
  font-feature-settings: "tnum" on;
}
@media (max-width: 800px) {
  .price {
    font-size: 3em;
  }
  a {
    font-size: 1em;
  }
}
</style>
