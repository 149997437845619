<template>
  <header>
    <div class="layout">
      <div class="logos">
        <img src="@/assets/images/elki-palki.svg" alt="" />
        <transition name="fade">
          <img src="@/assets/images/2022_logo.svg" alt="" v-if="show" />
        </transition>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 100);
  },
};
</script>
<style scoped>
.logos {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
header {
  position: relative;
  border-bottom: 6px solid #eda1d9;
}

header .layout {
  padding: var(--layoutPadding) var(--layoutPadding) 0 var(--layoutPadding);
  margin-bottom: -6px;
  position: relative;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s ease;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(calc(100% * 2));
}
@media (max-width: 1020px) {
  header .layout {
    padding-bottom: 1em;
  }
  header {
    margin-bottom: 4em;
  }
}
@media (max-width: 480px) {
  .logos {
    flex-direction: column;
  }
  img:first-child {
    width: 35%;
    margin-bottom: 0.5rem;
  }
  img {
    width: 100%;
  }
  img:last-child {
  }
  header {
    margin-bottom: 1rem;
    border-bottom: 3px solid #eda1d9;
  }
  header .layout{
    margin-bottom: -18px;
  }
}
</style>
