<template>
  <div class="text">
    <slot></slot>
  </div>
</template>
<script>
export default {};
</script>
<style lang="css">
.text {
  background: var(--textBg);
  border-radius: var(--radius);
  padding: var(--textPadding);
}
p {
  font-size: 1em;
  font-weight: lighter;
  margin-bottom: 0.6em;
}
p:last-child {
  margin-bottom: 0;
}
</style>