<template>
  <div class="content">
    <div class="block">
      <a href="/ny-purpose.jpg">
        <div class="item">
          <img src="@/assets/images/dish_icon.svg" />
          <div class="info">
            <p>новогоднее предложение</p>
            <div class="price"><small>от</small> 3500<small>₽</small></div>
          </div>
        </div>
      </a>
      <a href="/menu-constructor.pdf">
        <div class="item" @click="show = true">
          <img src="@/assets/images/dish_icon.svg" />
          <div class="info">
            <p>основное меню-конструктор</p>
            <div class="price"><small>от</small> 4000<small>₽</small></div>
          </div>
        </div></a
      >
    </div>
    <h3>Два варианта банкетного меню</h3>
    <ul>
      <li v-for="(item, i) in list" :key="i" v-html="item"></li>
    </ul>
  </div>
  <teleport to="#overlay">
    <Overlay v-if="show" @click.self="show = false">
      <UserForm :btnLabel="'Отправить заявку'" :persons="15">
        <ModalClose @click="show = false" />
      </UserForm>
    </Overlay>
  </teleport>
</template>
<script>
import UserForm from "./UserForm.vue";
import Overlay from "./Overlay.vue";
import ModalClose from "./modals/ModalClose.vue";
export default {
  name: "MenuVariants",
  components: { UserForm, Overlay, ModalClose },
  data() {
    return {
      show: false,
      list: [
        "Дополнительно можем предложить кофе-брейк, фуршетное и детское меню.",
        "Сервисный сбор 10% от итоговой стоимости меню.",
        "Пробковый сбор не взимается, можно привезти свои (сертифицированные) алкогольные напитки, а также безалкогольные напитки.",
      ],
    };
  },
};
</script>
<style scoped>
.content {
  --gap: 1.8em;
  display: grid;
  grid-gap: var(--gap);
}
.block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--gap);
}
ul {
  list-style: none;
  padding: 0;
}
li {
  padding-left: 1.9em;
  position: relative;
  margin-bottom: 1em;
}
li::before {
  position: absolute;
  content: "";
  height: 1.5em;
  width: 1.5em;
  background: url("~@/assets/images/plus_icon.svg") center no-repeat / 90%;
  top: 0;
  left: 0;
}
li:last-child {
  margin-bottom: 0;
}
.item {
  background: linear-gradient(180deg, #ff76da 26.47%, #eba5d8 100%);
  border-radius: 10px;
  padding: 1.4em 1em 0.7em;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all;
}
.item:hover {
  background: linear-gradient(180deg, #ff76da 100%, #eba5d8 100%);
}
p {
  margin: 1em 0 -0.5em 0;
}
.price {
  font-size: 2em;
}
.price small {
  font-size: 0.5em;
}
a {
  text-decoration: none;
}
@media (max-width: 800px) {
  .block {
    grid-template-columns: 1fr;
  }
}
</style>
