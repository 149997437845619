<template>
  <div class="form" v-if="send">
    <div class="message">
      Ваша заявка отправлена, наш менеджер свяжется с вами в ближайшее время.
    </div>
  </div>
  <div class="form" v-else>
    <slot></slot>

    <h3>{{ formTitle }}</h3>
    <label>
      Ваше Имя
      <input type="text" v-model.trim="name" placeholder="Имя" />
    </label>
    <!-- <input type="text" v-model.trim="date" placeholder="Дата проведения" /> -->
    <label>
      Номер телефона для связи
      <input
        type="text"
        v-model.trim="phone"
        placeholder="Телефон для связи"
        @input="checkPhone()"
        maxlength="15"
      />
    </label>
    <!-- <input type="text" v-model="email" placeholder="E-mail" /> -->
    <label>
      Количество человек
      <input
        type="number"
        v-model.trim="personsCount"
        min="15"
        max="200"
        placeholder="Количество человек"
      />
    </label>
    <!-- <div class="message">{{ msg }}</div> -->
    <button class="btn" :disabled="!valid" @click="valid && submitForm()">
      {{ buttonLabel }}
    </button>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "UserForm",
  props: ["btnLabel", "title", "persons"],
  data() {
    return {
      send: false,
      msg: "",
      name: "",
      date: "",
      phone: "+7 ",
      email: "",
      personsCount: this.persons,
    };
  },
  computed: {
    phoneValidation() {
      return !!this.phone && this.phone.length >= 15;
    },
    valid() {
      return !!this.name && this.phoneValidation && this.personsCount >= 15;
    },
    buttonLabel() {
      return this.btnLabel ? this.btnLabel : "Забронировать";
    },
    formTitle() {
      return this.title ? this.title : "Заявка на банкет";
    },
  },
  // updated() {
  //   this.personsCount = this.persons && this.persons
  // },
  methods: {
    async submitForm() {
      let data = {
        text: `Планирую 🎄 корпоратив на ${this.persons} персон \n${this.phone} ${this.name}`,
      };
      try {
        let result = axios.post(
          "https://api.veresk.club/notification/corp",
          data
        );
        this.send = result;
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    },
    checkPhone() {
      if (this.phone[0] !== "+") {
        this.phone = "+" + this.phone;
      } else if (this.phone[0] === "+" && this.phone[1] !== "7") {
        let arr = this.phone.split("");
        arr.splice(1, 0, "7");
        this.phone = arr.join("");
      }
      if (this.phone.length > 2 && this.phone[2] !== " ") {
        let arr = this.phone.split("");
        arr.splice(2, 0, " ");
        this.phone = arr.join("");
      }
      if (this.phone.length > 6 && this.phone[6] !== " ") {
        let arr = this.phone.split("");
        arr.splice(6, 0, " ");
        this.phone = arr.join("");
      }
      if (this.phone.length > 10 && this.phone[10] !== " ") {
        let arr = this.phone.split("");
        arr.splice(10, 0, " ");
        this.phone = arr.join("");
      }
    },
  },
};
</script>
<style scoped>
.form {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 500px;
  box-sizing: border-box;
  background: var(--bgVeresk);
  border-radius: var(--radius);
  padding: calc(var(--layoutPadding) / 2);
  width: 100%;
  grid-gap: 1rem;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
}
h3 {
  color: white;
  /* text-transform: uppercase; */
  /* text-transform: none; */
  font-size: 27px;
}
input {
  background: rgba(255, 255, 255, 0.4);
  border: 2px solid #ffffff;
  border-radius: 5px;
  padding: 0.5em;
  appearance: none;
  font-size: 1.4em;
  color: #353535;
  backdrop-filter: blur(3px);
}
input::placeholder {
  color: rgba(255, 255, 255, 0.65);
  font-size: 0.8em;
}
button.btn {
  line-height: 1;
  background: white;
  border-radius: 5px;
  color: #000;
  appearance: none;
  outline: none;
  border: none;
  padding: 1.8em 1.5em;
  text-transform: uppercase;
  font-size: 18px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: all 200ms;
}
button:disabled {
  opacity: 0.2;
}
button.btn:hover {
  box-shadow: 4px 2px 8px rgba(0, 0, 0, 0.3);
  background: rgb(238, 84, 205);
  color: white;
}
button.btn:disabled:hover {
  background: white;
  box-shadow: none;
  color: #000;
}
label {
  color: white;
  font-size: 1.2em;
}
.message {
  color: white;
  font-size: 1.3em;
  margin-bottom: 0;
}
</style>
